import React, { useState } from 'react';
import {
	Tooltip,
        Stack,
        Box,
        IconButton,
        InputGroup,
        Input,
        InputLeftAddon,
        InputRightElement,
        useClipboard } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import LinkMenu from "./LinkMenu";

function DataBox(props) {
    const [isCopied, setIsCopied] = useState(false);
    const { onCopy } = useClipboard(props.boxData);

    const handleCopy = () => {
        onCopy();
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000); // Reset isCopied after 1 second
    }
	return (

	<InputGroup>
  <InputLeftAddon width="9em">
    {props.boxText}
  </InputLeftAddon>
  <Input isReadOnly flex="1" value={props.boxData || ""} />
  <InputRightElement width="fit-content" display="flex" alignItems="center">
    {props.boxText.includes("URL") && !props.boxText.includes("Sanitized") && (
      <LinkMenu analyzeData={props.boxData} type="URL" titleText={`Pivot`} />
    )}
    {props.boxText === "Resolved IP" && (
      <LinkMenu analyzeData={props.boxData} type="IP" titleText="Pivot" />
    )}
    {props.boxText.includes("Domain") && (
          <LinkMenu analyzeData={props.boxData} type="Domain" titleText="Pivot"/>
        )}
<Tooltip label="Copied" isOpen={isCopied} placement="left">
  <IconButton
    size="sm"
    onClick={handleCopy}
    icon={<CopyIcon />}
    m={2}
  />
</Tooltip>
  </InputRightElement>
</InputGroup>
	)

}

export default function DomainBox(props) {
	const extractDomain = (url) => {
		  try {
		    const urlObj = new URL(url);
		    return urlObj.hostname;
		  } catch (error) {
		    return null; // or handle the error in some other way
		  }
		};
	const originalDomain = extractDomain(props.url);
	const destinationDomain = extractDomain(props.destUrl);
    return (
        <Box p={3} maxW={"100%"}>
            <Stack direction="column" scroll="auto">
                <DataBox boxText="Sanitized URL" boxData={props.sanUrl} />
		{props.url !== props.destUrl ? (
		          <>
	            <DataBox boxText="Original URL" boxData={props.url} />
	            <DataBox boxText="Dest. URL" boxData={props.destUrl} />
	          </>
	        ) :
		(
		<DataBox boxText="URL" boxData={props.url} />
		)}
		{originalDomain !== destinationDomain ? (
		          <>
	            <DataBox boxText="Original Domain" boxData={originalDomain} />
	            <DataBox boxText="Dest. Domain" boxData={destinationDomain} />
	          </>
	        ) :
		(
		<DataBox boxText="Domain" boxData={originalDomain} />
		)}
                <DataBox boxText="Resolved IP" boxData={props.resIp} />
            </Stack>
        </Box>
    )
}

