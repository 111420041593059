import { Skeleton, Box, Stack, useColorMode } from '@chakra-ui/react';

const WebsiteSkeleton = () => {
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: '#1e1e1e' };
  // More pronounced start and end colors for dark mode
  const skeletonStartColor = { light: 'gray.200', dark: '#2d2d2d' };
  const skeletonEndColor = { light: 'gray.300', dark: '#505050' };

  return (
    <Box padding="6" boxShadow="lg" bg={bgColor[colorMode]}>
      <Skeleton height="20px" my="10px" startColor={skeletonStartColor[colorMode]} endColor={skeletonEndColor[colorMode]} />
      <Skeleton height="40px" my="10px" startColor={skeletonStartColor[colorMode]} endColor={skeletonEndColor[colorMode]} />
      <Stack>
        <Skeleton height="200px" my="10px" startColor={skeletonStartColor[colorMode]} endColor={skeletonEndColor[colorMode]} />
        <Skeleton height="150px" my="10px" startColor={skeletonStartColor[colorMode]} endColor={skeletonEndColor[colorMode]} />
        <Skeleton height="50px" my="10px" startColor={skeletonStartColor[colorMode]} endColor={skeletonEndColor[colorMode]} />
      </Stack>
    </Box>
  );
};

export default WebsiteSkeleton;

