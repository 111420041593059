import { useColorMode, useColorModeValue, Stack, Box, Center, Image, Text } from "@chakra-ui/react";
import UrlBox from './UrlBox';
import AlertBox from './AlertBox';
import blackLogo from './blackLogo.png';
import whiteLogo from './whiteLogo.png';

export default function Splash(props) {
    const { colorMode } = useColorMode(); // Use useColorMode to get the current color mode
    const logo = useColorModeValue(blackLogo, whiteLogo); // Determine which logo to use based on the theme
    const textColor = colorMode === 'dark' ? 'white' : 'black'; // Determine text color based on the theme

    return (
        <Center h="70vh">
            <Stack alignItems="center" direction="column" spacing={5}>
                <Image src={logo} alt="RawHTTP Logo" boxSize={["90%","25%"]} />
                <Box width={["95%","40%"]}>
                    <UrlBox
                        textColor={textColor} // Correctly pass textColor as a prop
                        size="lg"
                        onUrlChange={props.onUrlChange}
                    />
                </Box>
                {props.error ? (
                    <Box>
                        <AlertBox errorText={props.error} />
                    </Box>
                ) : ""}
                <Box p={3} width={["100%","30%"]}>
                    <Text fontSize="md" textAlign="center">
                        Created to help in the investigation of suspicious links, RawHTTP will show you what a page looks like along with its HTTP information.
                    </Text>
                </Box>
            </Stack>
        </Center>
    );
}

