import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = extendTheme({
    styles: {
      global: (props) => ({
        body: {
          bg: mode('white', '#1e1e1e')(props),
          color: mode('black', 'white')(props),
        },
      }),
    },
  })

export default theme
