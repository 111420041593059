import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

function RawTable({ data }) {
  // Check if data is available and not empty
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  // Create column headers based on the keys of the first data object
  const columns = Object.keys(data[0]).map(key => ({
    header: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the header
    accessor: key
  }));

  return (
	<Table variant="striped" size="sm">
  <Thead>
    <Tr>
      {columns.map((column, index) => (
        <Th key={index} width={column.accessor === 'url' || column.accessor === 'domain' ? '100%' : 'auto'}>
          {column.header}
        </Th>
      ))}
    </Tr>
  </Thead>
  <Tbody>
    {data.map((row, rowIndex) => (
      <Tr key={rowIndex}>
        {columns.map((column, columnIndex) => (
          <Td key={columnIndex}>{row[column.accessor]}</Td>
        ))}
      </Tr>
    ))}
  </Tbody>
</Table>


  );
}

export default RawTable;

