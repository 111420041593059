import { Fade, Box, HStack, Flex } from '@chakra-ui/react';
import BannerLogo from './BannerLogo';
import UrlBox from './UrlBox';
import WhatModal from './WhatModal';
import SettingsModal from './settings/SettingsModal';

export default function Header(props) {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            p={2}
            backgroundColor="black"
        >
      <Fade in={!props.emptyUrl} width={["100%","30%"]} >
          <Flex spacing={5} display={props.emptyUrl ? "none" : "flex"} >
              <Box paddingLeft={3}>
                 <BannerLogo />
              </Box>
              <Box paddingLeft={3} width={["100%","30vw"]}>
                  <UrlBox 
                   size="sm"
	           textColor="white"
                   onUrlChange={props.onUrlChange} />
                  
              </Box>
          </Flex>
      </Fade>

      <Box>
      </Box>

      <HStack>
          <SettingsModal />
          <WhatModal />
      </HStack>

  </Flex>

        )

}

