import React from 'react';
 
import Splash from './Splash';
import Header from './Header';
import MainResults from './results/MainResults';

const baseState =  {
             url: null,
             error: null,
             sanUrl: "",
             resIp: "",
             headers: [],
             source: {},
             contentType: "",
	     consoleMessages: " ",
             requestedUrls: ["Loading"],
             certData: {},
             destUrl: null
                }

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.handleUrlChange = this.handleUrlChange.bind(this);
       this.state = baseState;
    }

    componentDidMount () {
       const script = document.createElement("script");
       script.innerHTML = `
               (function(f, a, t, h, o, m){
            	a[h]=a[h]||function(){
            		(a[h].q=a[h].q||[]).push(arguments)
            	};
            	o=f.createElement('script'),
            	m=f.getElementsByTagName('script')[0];
            	o.async=1; o.src=t; o.id='fathom-script';
            	m.parentNode.insertBefore(o,m)
            })(document, window, '//f.rawhttp.com/tracker.js', 'fathom');
            fathom('set', 'siteId', 'QCWWD');
            fathom('trackPageview');
       `
       document.body.appendChild(script);
    }

    handleUrlChange(newUrl) {
        this.setState(prevState => ({
                    requestedUrls:["Loading"],
                    imgSrc: {"success":false,
                                "url":""},
                    url: newUrl
        })
        );

        fetch("/api/fast", {

            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"url":newUrl})
        })
        .then( function(res){
            if (res.ok){
                   return res.json()
                }else{
                    throw new Error("It's broke, try again later");
                }
           })
        .then( (j) => {
            if (j.success){
                if (j.content['url'].startsWith("http://44.203.184.225")){
                    window.location.href = atob("aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g/dj1kUXc0dzlXZ1hjUQ==");
                }
                  this.setState(
                  {
                      sanUrl: j.content['sanitized'],
                      resIp: j.content['resolved_ip'],
                      url: j.content['url'],
                      destUrl: j.content['dest_url'],
                      headers: j.content['headers'],
                      source: j.content['source'],
                      contentType: j.content['content-type'],
                      certData: j.content['cert'],
                      error: null, 
                  })
           }else {
              this.setState(baseState);
              this.setState(
                {error: j.content}
              )
           }

           })
        .catch( (err)  => {
            this.setState(
                {
                    error: "It's broke. Try again later. " + err
                })
        })
       .then( () => {
            if (!this.state.error) {
               fetch("/api/image", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({"url":newUrl})
                })
                .then( res => res.json())
                .then( j => {
                    if (j.success){
/*                        this.setState( (prevState) =>
                            {
                                let cert = Object.assign({}, prevState.certData);
                                cert['alt_subjects'] = j.content['subject_alt_names'];
                                return { certData: cert };
                            })
                            */
                        this.setState( (prevState) =>
                        ({
                            imgSrc: {
                                "success": true,
                                "url":"data:image/png;base64,".concat(j.content['base64'])
                                },
                            requestedUrls: j.content['requested_urls'],
                            consoleMessages: j.content['console_messages'],
                         }))

                    }else {
                        this.setState(
                            {
                                imgSrc:{ "success": true,
                                        "url": "it broke"},
                                requestedUrls: ["Error retrieving information"]
                            });
                          }
                       })

    }})}

    render() {
        if ((!this.state.sanUrl) || (this.state.error)) {
            return (
            <React.Fragment>
                <Header emptyUrl={true} />
                    <Splash 
                        onUrlChange={this.handleUrlChange} 
                        error={this.state.error}
                    />
            </React.Fragment>
            )
       } else {
            return (
            <React.Fragment>
                <Header onUrlChange={this.handleUrlChange} emptyUrl={false} />
                <MainResults
                   url = {this.state.url}
                   sanUrl = {this.state.sanUrl} 
                   destUrl = {this.state.destUrl}
                   resIp = {this.state.resIp}
                   headers = {this.state.headers}
                   imgSrc  = {this.state.imgSrc}
                   details  = {this.state.details}
                   source = {this.state.source}
                   contentType = {this.state.contentType}
                   requestedUrls = {this.state.requestedUrls}
		   consoleMessages = {this.state.consoleMessages}
                   certData = {this.state.certData}
                />
           </React.Fragment>
         )
       } 
    }
}


export default Main

