/*import {
	Center,
	Box,
	Text
	} from "@chakra-ui/react";

import DomainBox from './DomainBox';
*/


export default function TopInfo(props) {

	return (
		    <>
		    </>
		  );
}
