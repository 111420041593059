import {
  FormControl, 
  Switch,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import { SettingsIcon } from "@chakra-ui/icons";

import CustomServiceForm from './CustomServiceForm';
import CustomServicesList from './CustomServicesList';

export default function SettingsModal(props) {
	const { colorMode, toggleColorMode } = useColorMode()
	const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="outline" _hover={{bg: "gray.800" }} color="white" onClick={onOpen}><Text m={1}>Settings</Text><SettingsIcon /></Button>
 
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={props.themeColor} maxW={["80%","50%"]}>
	  <Box textAlign="center">
          <ModalHeader marginTop={5} fontSize="2xl">
	  	Settings	
          </ModalHeader>
	  <Text> Settings are saved in your browser's localStorage </Text>
	  </Box>
          <ModalCloseButton />
          <ModalBody>
          </ModalBody>
	  	<Box p={5}>
			<WhatHeader>Dark Mode?</WhatHeader>
            <FormControl display="flex" mt={3}>
              <Switch mb={5} id="dark-mode-switch" isChecked={colorMode === 'dark'} onChange={toggleColorMode} size='lg'/>
            </FormControl>
		  <WhatHeader> Custom Pivot Services</WhatHeader> 
		  <CustomServicesList />
		  <Text fontSize="l" fontWeight="bold" mt={5} ml={3}> Add New </Text>
		  <CustomServiceForm />
	  		
	  	</Box>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function WhatHeader(props) {
    return <Text fontSize="xl" fontWeight="bold" pt={2}>{props.children}</Text> 
}
