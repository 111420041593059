import React, { useState, useEffect } from 'react';
import { Button, Flex, FormControl, Input, Text } from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

const CustomServicesList = () => {
  const [customServices, setCustomServices] = useState([]);

  useEffect(() => {
    const loadServices = () => {
      const loadedServices = JSON.parse(localStorage.getItem('customServices')) || [];
      setCustomServices(loadedServices);
    };

    loadServices(); // Initial load

    const handleServiceAdded = () => {
      loadServices(); // Reload on custom event
    };

    window.addEventListener('customServiceAdded', handleServiceAdded);

    return () => {
      window.removeEventListener('customServiceAdded', handleServiceAdded);
    };
  }, []);
  
  const handleDelete = (serviceName) => {
    const updatedServices = customServices.filter(service => service.name !== serviceName);
    setCustomServices(updatedServices);
    localStorage.setItem('customServices', JSON.stringify(updatedServices));
    window.dispatchEvent(new CustomEvent('customServiceDeleted'));
  };

  return (
    <Flex direction="column" gap="4">
      {customServices.length === 0 ? (
        <Text m={3}>No custom services added.</Text>
      ) : (
        customServices.map((service, index) => (
          <Flex key={index} justifyContent="center" alignItems="center" gap="4" width="100%" mt={2}>
            <FormControl id={`serviceType-${index}`} width="8em">
              <Input value={service.type} isReadOnly />
            </FormControl>

            <FormControl id={`serviceName-${index}`} width="20%">
              <Input value={service.name} isReadOnly />
            </FormControl>

            <FormControl id={`serviceUrl-${index}`} flex="1">
              <Input value={service.url} isReadOnly />
            </FormControl>
		<Button onClick={() => handleDelete(service.name)} size="sm">
	            <DeleteIcon />
	        </Button>
          </Flex>
        ))
      )}
    </Flex>
  );
};

export default CustomServicesList;

