import React from 'react';
import { Text, List, ListItem, VStack, Button } from '@chakra-ui/react';

const CertificateInfo = ({ certData }) => {
  if (certData && certData.success) {
    const { subject, issuer, valid_from, valid_to, serial_number, alt_subject_names, certificate_binary } = certData.content;

    const formatObjectToList = (obj) => (
      <List styleType="none" ml={4}>
        {Object.entries(obj).map(([key, value], index) => (
          <ListItem key={index}>
            <Text as="span" fontSize="sm" fontWeight="bold">{`${key}:`}</Text>
            <Text as="span" maxW="70%" ml={3} fontSize="sm">{value}</Text>
          </ListItem>
        ))}
      </List>
    );

    const handleDownloadCertificate = () => {
      const byteArray = new Uint8Array(atob(certificate_binary).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${serial_number}_certificate.crt.bin`;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    return (
      <VStack align="start" spacing={2}>
        <Button onClick={handleDownloadCertificate}> Download Certificate</Button>
        <Text as="span" fontSize="sm" fontWeight="bold">Subject:</Text>
        {formatObjectToList(subject)}
        <Text as="span" fontSize="sm" fontWeight="bold">Issuer:</Text>
        {formatObjectToList(issuer)}
        <Text as="span" fontSize="sm" fontWeight="bold">Valid From:</Text>
        <Text as="span" l={3} fontSize="sm">{valid_from}</Text>
        <Text as="span" fontSize="sm" fontWeight="bold">Valid To:</Text>
        <Text as="span" maxW="70%" ml={3} fontSize="sm">{valid_to}</Text>
        <Text as="span" fontSize="sm" fontWeight="bold">Serial Number:</Text>
        <Text as="span" maxW="70%" ml={3} fontSize="sm">{serial_number.toString()}</Text>
        <Text as="span" fontSize="sm" fontWeight="bold">Subject Alternative Names:</Text>
        {alt_subject_names.map((name, index) => (
          <Text key={index} as="span" maxW="70%" ml={3} fontSize="sm">{name}</Text>
        ))}
      </VStack>
    );
  } else {
    return <Text>No certificate data available.</Text>;
  }
};

export default CertificateInfo;

