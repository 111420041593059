import * as React from "react"
import { 
    ChakraProvider,
    CSSReset,
    } from '@chakra-ui/react';
import theme from './theme';
import Main from './components/Main';

function App() {
  return (
  <ChakraProvider theme={theme}>
            <CSSReset />
            <Main />
    </ChakraProvider>
  );

}

export default App;

