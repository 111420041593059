import React, { useEffect, useState } from "react";
import {
  Text,
  Portal,
  Button,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { urlServices, domainServices, ipServices } from "../../config";

export default function LinkMenu(props) {
  const [customServices, setCustomServices] = useState([]);

  useEffect(() => {
    const loadCustomServices = () => {
      const storedServices = JSON.parse(localStorage.getItem('customServices')) || [];
      const filteredServices = storedServices.filter(service => service.type === props.type);
      setCustomServices(filteredServices);
    };

    loadCustomServices(); // Initial load of custom services

    const handleServiceAdded = () => {
      loadCustomServices(); // Reload on custom event
    };

    window.addEventListener('customServiceAdded', handleServiceAdded);
    window.addEventListener('customServiceDeleted', loadCustomServices);

    return () => {
      window.removeEventListener('customServiceAdded', handleServiceAdded);
      window.removeEventListener('customServiceDelete', loadCustomServices);
    };
  }, [props.type]); // Rerun effect when props.type changes

  let services = [];
  
  if (props.type === "URL") {
    services = urlServices;

  } else if (props.type === "Domain") {
    services = domainServices;
  } else if (props.type === "IP") {
    services = ipServices;
  }

  const sortedServices = [...services].sort((a, b) => a.name.localeCompare(b.name));


return (
  <Menu>
    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} width="auto" size="sm">
	{`${props.titleText}`}
    </MenuButton>
    <Portal>
      <MenuList>
	<MenuGroup title={<Text as="span" fontWeight="bold" fontSize="md">{`Pivot by ${props.type}`}</Text>}>
	<Text
		  m={2}
		  fontFamily="Source Code Pro"
		  fontSize="12"
		  style={{
		    maxWidth: "25ch", // Adjust the value to the desired character limit
		    overflow: "hidden",
		    whiteSpace: "nowrap",
		    textOverflow: "ellipsis",
		  }}
		>
		  {props.analyzeData}
		</Text>
	 <MenuDivider />
          {sortedServices.map((service) => (
            <MenuItem
              as="a"
              href={service.url(props.analyzeData)}
              target="_blank"
              rel="noopener"
              key={`${service.name}-${props.type}`}
            >
              {service.name}
            </MenuItem>
          ))}
        </MenuGroup>
        {customServices.length > 0 && (
		<MenuGroup title={<Text fontWeight="bold" fontSize="md">{` Custom ${props.type} Tools`}</Text>}>
            {customServices.map((customService) => (
              <MenuItem
                as="a"
                href={customService.url.replace("%s", props.analyzeData)}
                target="_blank"
                rel="noopener"
                key={`${customService.name}-${props.type}`}
              >
                {customService.name}
              </MenuItem>
            ))}
          </MenuGroup>
        )}
      </MenuList>
    </Portal>
  </Menu>
);

}

