import { Button } from '@chakra-ui/react';

export default function ExportButton({ data }) {
  const handleExport = () => {
    const csv = convertToCSV(data);
    downloadCSV(csv, 'RawHTTP_resources_export.csv');
  };

  function convertToCSV(data) {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(row =>
      Object.values(row).map(value => `"${value}"`).join(',')
    ).join('\n');

    return `${headers}\n${rows}`;
  }

  function downloadCSV(csvData, filename) {
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return <Button onClick={handleExport} m={3}>Export as CSV</Button>;
}

