import { 
    Center,
    Text,
    Tooltip,
    Image,
    Box,
    } from "@chakra-ui/react";

import { CloseIcon } from '@chakra-ui/icons';
import WebSkeleton from './WebSkeleton';

export default function ImageBox(props) {
    function tryOpen() {
        var image = document.createElement("img");
        image.src = props.imgSrc['url'];            
        
        try {
            var w = window.open("");
            w.document.write(image.outerHTML);
        } catch{
        }
}
  return (
        <>
       { props.imgSrc['success'] ?
            (
                <>
                { props.imgSrc['url'].startsWith("data") ?
                  (
                <Box boxShadow="md" m={3} overflow="auto" id="imagecontainer">
                    <Tooltip placement="top" label="Click for fullscreen in a new tab">
                        <Image onClick={ props.imgSrc['success'] ? tryOpen : ""} src={props.imgSrc['url']} />
                    </Tooltip>
                </Box>
                    ):
                    <Center>
                        <Box borderRadius="lg" align="center" bg="red.400" w="50%" p={3} >
                        <CloseIcon w="200px" h="100px" />
                        <Text fontSize="3xl"> Unable to render screenshot </Text>
                        </Box>
                    </Center>

                }
                </>

            )
            :
            (
		    <WebSkeleton />
            )
       }
       </>


  )
}
