import React from 'react';
import { Image, Box } from '@chakra-ui/react';

import logo from './logo128.png';


export default function BannerLogo(props) {
    return (
        <Box>
           <a href="/" >
                <Image src={logo} padding={1} maxWidth="128px" />
            </a>
        </Box>

    )

}

