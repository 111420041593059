import React, { useState } from 'react';
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import { Box, FormHelperText, Flex, FormControl, Select, Input, Button } from "@chakra-ui/react";

const CustomServiceForm = () => {
  const [newServiceName, setNewServiceName] = useState('');
  const [newServiceUrl, setNewServiceUrl] = useState('');
  const [newServiceType, setNewServiceType] = useState('URL');
const [error, setError] = useState(false);
const [errorMessage, setErrorMessage] = useState("");

const handleNewServiceSubmit = () => {
  setError(false);
  setErrorMessage('');

  if (newServiceName.trim() === '') {
    setError(true);
    setErrorMessage('Service Name cannot be blank.');
    return;
  }

  if (!isValidServiceUrl(newServiceUrl)) {
    setError(true);
    setErrorMessage('Invalid service URL. Please enter a valid URL with %s as the indicator.');
    return;
  }

  const existingServices = JSON.parse(localStorage.getItem('customServices')) || [];
  if (existingServices.some(service => service.name === newServiceName)) {
    setError(true);
    setErrorMessage('Service Name already exists. Please choose a unique name.');
    return;
  }

  const newService = { type: newServiceType, name: newServiceName, url: newServiceUrl };
  existingServices.push(newService);
  localStorage.setItem('customServices', JSON.stringify(existingServices));

  // Dispatch custom event
  const event = new CustomEvent('customServiceAdded', { detail: newService });
  window.dispatchEvent(event);

  setNewServiceName('');
  setNewServiceUrl('');
  setNewServiceType('');
};

const isValidServiceUrl = (url) => {
  return /%s/.test(url);
};

return (
  <Box>
    <Flex
      direction={["column", "row"]} // Use a column layout for mobile and row for non-mobile
      justifyContent="center"
      alignItems={["center", "flex-start"]} // Center elements horizontally on mobile and align them to the start on non-mobile
      gap="4"
      width="100%"
    >
      <FormControl id="serviceType" width={["100%", "8em"]}>
        <Select value={newServiceType} onChange={(e) => setNewServiceType(e.target.value)}>
          <option value="URL">URL</option>
          <option value="Domain">Domain</option>
          <option value="IP">IP</option>
        </Select>
        <FormHelperText>Indicator Type</FormHelperText>
      </FormControl>

      <FormControl id="serviceName" width={["100%", "20%"]}>
        <Input
          placeholder="Service Name"
          value={newServiceName}
          onChange={(e) => setNewServiceName(e.target.value)}
        />
        <FormHelperText>Custom Name</FormHelperText>
      </FormControl>

      <FormControl id="serviceUrl" flex="1">
        <Input
          placeholder="Service URL"
          value={newServiceUrl}
          onChange={(e) => setNewServiceUrl(e.target.value)}
        />
        <FormHelperText>Put %s where the indicator should go in the URL.</FormHelperText>
      </FormControl>

      <Button colorScheme="blue" onClick={handleNewServiceSubmit}>Add</Button>
    </Flex>
    {error && (
      <Alert status="error" mt={2}>
        <AlertIcon />
        <AlertDescription>{errorMessage}</AlertDescription>
      </Alert>
    )}
  </Box>
);




};

export default CustomServiceForm;

