// web/src/config.js

export const ipServices = [
   {
    name: 'GreyNoise',
    url: (ip) => `https://viz.greynoise.io/ip/${encodeURIComponent(ip)}`,
  },
  {
    name: 'Netify',
    url: (ip) => `https://www.netify.ai/resources/ips/${encodeURIComponent(ip)}`,
  },
   {
    name: 'AlienVault OTX',
    url: (ip) => `https://otx.alienvault.com/indicator/ip/${ip}`,
  },
  {
    name: 'Censys',
    url: (ip) => `https://search.censys.io/hosts/${ip}`,
  },
  {
    name: 'Shodan',
    url: (ip) => `https://www.shodan.io/search?query=${ip}`,
  },
  {
    name: 'AbuseIPDB',
    url: (ip) => `https://www.abuseipdb.com/check/${ip}`,
  },
  {
    name: 'IPinfo',
    url: (ip) => `https://ipinfo.io/${ip}`,
  },
  {
    name: 'MXToolbox',
    url: (ip) => `https://mxtoolbox.com/SuperTool.aspx?action=blacklist%3a${ip}&run=toolpage`,
  },
  {
    name: 'Robtex',
    url: (ip) => `https://www.robtex.com/ip-lookup/${ip}`,
  },
  {
    name: 'VirusTotal',
    url: (ip) => `https://www.virustotal.com/gui/ip-address/${ip}`,
  },
  {
    name: 'ARIN',
    url: (ip) => `https://search.arin.net/rdap/?query=${ip}`,
  },
];

export const urlServices = [
  {
    name: 'SecurityTrails',
    url: (url) => `https://securitytrails.com/domain/${encodeURIComponent(url)}/dns`,
  },
  {
    name: 'Qualys SSL Labs',
    url: (url) => `https://www.ssllabs.com/ssltest/analyze.html?d=${encodeURIComponent(url)}`,
  },
  {
    name: 'Joe Sandbox',
    url: (url) => `https://www.joesandbox.com/search?q=${encodeURIComponent(url)}`,
  },
  {
    name: 'AlienVault OTX',
    url: (url) => `https://otx.alienvault.com/indicator/url/${url}`,
  },
];
export const domainServices = [
   {
    name: 'AlienVault OTX',
    url: (domain) => `https://otx.alienvault.com/indicator/domain/${domain}`,
  },
   {
    name: 'MXToolbox',
    url: (domain) => `https://mxtoolbox.com/SuperTool.aspx?action=blacklist%3a${encodeURIComponent(domain)}&run=toolpage`,
  },
  {
    name: 'VirusTotal',
    url: (domain) => `https://www.virustotal.com/gui/domain/${encodeURIComponent(domain)}`,
  },
  {
    name: 'SecurityTrails',
    url: (domain) => `https://securitytrails.com/domain/${encodeURIComponent(domain)}/dns`,
  },
  {
    name: 'crt.sh',
    url: (domain) => `https://crt.sh/?q=${encodeURIComponent(domain)}`,
  },
  {
    name: 'Censys',
    url: (domain) => `https://search.censys.io/search?resource=hosts&q=${encodeURIComponent(domain)}`,
  },
  {
    name: 'DomainTools',
    url: (domain) => `https://whois.domaintools.com/${encodeURIComponent(domain)}`,
  },
  // ... additional services as needed
];

