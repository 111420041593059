import { Tooltip, Code, Text, Heading, Box } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";

import CertificateInfo from './CertInfo';

function HeaderText(props) {
    return (
       <Box>
           <Text as="span" fontSize="sm" fontWeight="bold">
            {props.name}:
           </Text>
           <Text as="span" maxW="70%" marginLeft={3} fontSize="sm">
              {props.value }
           </Text>
       </Box>
    )
}

function PageData(props) {
    return (
    <Heading size="md">
       <Tooltip placement="auto" hasArrow label={props.reason}>
          <Code p={1} fontSize="xl">
              {props.statusCode}
         </Code>
       </Tooltip>
       <Text p={2} as="span">
           {props.pageUrl}
       </Text>
    </Heading>
    )
}

export default function HeaderBox(props) {
    var headerItems = props.headers.map( (r, index) =>  {
            return (
            <Box key={index.toString()} p={3}>
                <PageData statusCode={r.status_code} pageUrl={r.url} reason={r.reason} />
                {r.headers.map((h,i) => {
                    return (
                          <HeaderText key={i.toString()} name={h.header} value={h.value} />
                          )
                    })}
                    <hr />
               </Box>
               )
            })            

    return (
	    <Accordion allowToggle>
		  <AccordionItem>
		  <AccordionButton>
	    		<Heading size="lg"> Certificate </Heading>
	    		<AccordionIcon />
	    	</AccordionButton>
		    <AccordionPanel pb={4}>
		            <Box p={3}>
		    		<CertificateInfo certData={props.certData}/>
		       	     </Box>
	   	</AccordionPanel>
	    	</AccordionItem>
		  <AccordionItem>
		  <AccordionButton>
	    		<Heading size="lg"> HTTP Headers </Heading>
	    		<AccordionIcon />
	    	</AccordionButton>
		    <AccordionPanel pb={4}>
		            <Box p={3}>
		       	     { headerItems }
		       	     </Box>
	   	</AccordionPanel>
	    	</AccordionItem>
	    	</Accordion>

    )
}

