import React from 'react';

import { Flex, Stack, Box } from "@chakra-ui/react";
//import DomainBox from './DomainBox';
import TopInfo from './TopInfo';
import ImageBox from './ImageBox';
import HeaderBox from './HeaderBox';
import DetailBox from './DetailBox';
import DomainBox from './DomainBox';

export default function MainResults(props) {
    return (
        <Stack direction="column">
            <TopInfo
                sanUrl={props.sanUrl}
                url={props.url}
                resIp={props.resIp}
                destUrl={props.destUrl}
             />
            <hr />
            <Flex wrap="wrap" flex="1" id="middle">
                <Box width={["100%","40%"]} id="headerdiv">
		    <DomainBox
	                sanUrl={props.sanUrl}
	                url={props.url}
	                resIp={props.resIp}
	                destUrl={props.destUrl}
	             />
                    <HeaderBox 
	    		headers={props.headers}
	                certData = {props.certData}
	   	     />
                </Box> 
                <Box 
                    maxH="100vh"
                    overflow="auto"
                    width={["100%","60%"]}
                    id="imagediv"
                >
                    <ImageBox imgSrc={props.imgSrc}/>
                </Box>
            </Flex>
                <DetailBox 
                    sourceData= {props.source}
                    contentType = {props.contentType}
                    requestedUrls = {props.requestedUrls}
	   	    consoleMessages = {props.consoleMessages}
                />
        </Stack>

    )


}

