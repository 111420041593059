import React, { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
    Button,
    InputGroup,
    Input,
    InputRightElement,
} from '@chakra-ui/react';

const UrlBox = (props) => {
    const [urlValue, setUrlValue] = useState('');

    const handleSubmit = event => {
        event.preventDefault();
        props.onUrlChange(urlValue);
    }

    return (
        <form onSubmit={handleSubmit}>
            <InputGroup size={props.size}>
                <Input
                     autoFocus
                     focusBorderColor={props.textColor} // Use props.textColor for focus border
                     id="box"
                     placeholder="http://example.com"
                     _placeholder={{ color: "gray.400" }}
                     style={{ color: props.textColor }} // Apply conditional text color
                     width="100%"
                     onChange={event => setUrlValue(event.currentTarget.value)}
                />

                <InputRightElement id="right">
                    <Button type="submit" size="sm">
                        <ArrowForwardIcon />
                    </Button>
                </InputRightElement>
            </InputGroup>
       </form>
    );
}

export default UrlBox;

