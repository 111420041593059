import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"

import {
    Code,
    Box,
    Text,
    useDisclosure,
    Button,
    UnorderedList,
    ListItem,
} from "@chakra-ui/react"

export default function WhatModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button variant="outline" _hover={{bg: "gray.800" }} color="white" onClick={onOpen}>What?</Button>
 
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={props.themeColor} maxW="45rem">
          <ModalHeader marginTop={5}>
            RawHTTP.com was created to help in the investigation of suspicious links. There are a few key areas:
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          </ModalBody>
            <Box p={5}>
                <WhatContent />
            </Box>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function WhatHeader(props) {
    return <Text fontSize="xl" fontWeight="bold">{props.children}</Text> 
}

function WhatText(props) {
    return (
        <Text p={3}>
            {props.children}
        </Text>
    )
}

function WhatContent() {
    return (
     <>
	<div>
	          <WhatHeader>Warning</WhatHeader>
	          <WhatText>If the URL entered performs some action for example, unsubscribing from a mailing list, those actions will still be taken. Be careful not to submit URLs that contain personal information such as referral links if you're concerned about sending that data to the target.</WhatText>
	          
	          <WhatHeader>Limitations</WhatHeader>
	          <WhatText>In an effort to be helpful, domains that contain [.] will be replaced with a single dot and [:] in the scheme/protocol will be replaced with a single colon. This takes away a little bit of the annoyance of pasting sanitized URLs, but could, in theory, give an inconsistent result if that was unexpected.</WhatText>
	          
	          <WhatHeader>HTTP Headers</WhatHeader>
	          <WhatText>The HTTP Headers section does two things. One, it shows the order of requests that took place to reach the destination. For instance, if there are redirects, it'll display the 302 or 301 status code, and below it will be the next request until it reaches the destination. Secondly, it shows the HTTP response headers for requests it makes, which can give insight into the server configuration for the URL.</WhatText>
	          
	          <WhatHeader>Screenshot</WhatHeader>
	          <WhatText>In the event of investigating suspicious links in an email, for example, and it is suspected to be phishing, it's convenient to be able to see what the phishing page looks like. The screenshot as displayed should faithfully look the same as it would in a web browser, but it may not be exact. Clicking the screenshot will open a full-sized version in a new tab.</WhatText>
	          
	          <WhatHeader>Source</WhatHeader>
	          <WhatText>This section has the ability to display the full HTML of the resulting web page, which may help the investigator look for malicious content. It also has the ability to add syntax-highlighting. By clicking 'Add Syntax Highlights' the HTML should be easier to read. Note that on large webpages this may be taxing on your computer.</WhatText>
	          
	          <WhatHeader>Resources</WhatHeader>
	          <WhatText>This section displays all the resources that the destination URL makes. This will include stylesheets, images, scripts, and more.</WhatText>
	          
	          <WhatHeader>Console</WhatHeader>
	          <WhatText>This section displays all the browser console messages that you would see in your browsers developer tools when loading the destination URL.</WhatText>
	    	<WhatHeader> Custom Pivot Services </WhatHeader>
	    	<WhatText>Under settings, Custom Pivot services allows you to add 3rd party web resources to pivot a particular indicator (URL, Domain, IP) to.  This will create a new tab and since it's being requested from your browser can include any local tools that aren't accessible to the internet. Provide the type of indicator, a name you'll recognize, and a URL to pivot to, with <Text fontFamily="mono" as="span">%s</Text> in the place of the URL where the indicator should go. These are saved in your local browser storage and not transmitted to RawHTTP. </WhatText>
	        </div>
       <hr />
        <Text as="h4" fontWeight="bold">
            Donations
        </Text>
        <Text paddingLeft={3}>
        Bitcoin: 3FqX4X3j6J8BcFKPnirG7L7LNUd4Xxu8Jn
        </Text>
        <Text paddingLeft={3}>
        Dogecoin: DRzthaf8RHSdocKsZZ9UxUWW9i277ap9ci 
        </Text>
        <Text paddingLeft={3}>
        RawHTTPcoin: bG9sLiBqdXN0IGtpZGRpbmcu
        </Text>
        <Text as="h4" fontWeight="bold">
           Contact 
        </Text>
        <Text paddingLeft={3}>
            contact[@]rawhttp[.]com 
        </Text>
     </>
    )
}
