import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { ColorModeScript } from '@chakra-ui/react';
import theme from './theme';

// Get the container element
const container = document.getElementById('rawhttp');

// Create a root
const root = createRoot(container);

// Render the app
root.render(
	  <React.StrictMode>
	    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
	    <App />
	  </React.StrictMode>
);
